import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

const CartContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
`;

const CartStyle = styled.div`
  pointer-events: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30%;
  max-width: 30%;
  background-color: white;
  padding: 15px;

  @media (max-width: 850px) {
    width: 100%;
    max-width: 100%;
  }
`;

const CartEmpty = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

const CartOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer;
  pointer-events: auto;
`;

const CartProductContainer = styled.div`
  border-bottom: 1px lightgrey solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  max-height: 60px;
`;

const CartProductImage = styled.img`
  max-height: 80%;
  margin-right: 10px;
`;

const CartProductName = styled.div`
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const CartProduct = ({ product, size }) => {
  return (
    <CartProductContainer key={product.name}>
      <CartProductImage alt="product" src={product.image} />
      <CartProductName>{product.name}</CartProductName>
      <span>{size.name}</span>
    </CartProductContainer>
  );
};

class Cart extends React.Component {
  render() {
    const { items, showCart } = this.props;
    let content;
    if (items && items.length) {
      content = items.map(CartProduct);
    } else {
      content = <CartEmpty>Your cart is empty</CartEmpty>;
    }
    return (
      <CartContainer>
        {showCart && <CartOverlay onClick={this.props.onHide} />}
        {showCart && <CartStyle>{content}</CartStyle>}
      </CartContainer>
    );
  }
}

export default connect(state => state.basket)(Cart);
