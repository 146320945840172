import React from 'react';
import Product from 'components/Product';

const BRA = {
  id: 9837899,
  name: 'Bra',
  price: 50,
  // For the plugin
  client_id: 5,
  client_product_id: 'bra',
  image: `${process.env.REACT_APP_STATIC_URL}/plugin-demo-products/bra.webp`,
  available_sizes: [
    {
      name: '85A',
      id: '1',
      in_stock: true,
    },
    {
      name: '90A',
      id: '2',
      in_stock: true,
    },
    {
      name: '85B',
      id: '3',
      in_stock: true,
    },
    {
      name: '90B',
      id: '4',
      in_stock: true,
    },
    {
      name: '95B',
      id: '5',
      in_stock: true,
    },
    {
      name: '100B',
      id: '6',
      in_stock: true,
    },
    {
      name: '85C',
      id: '7',
      in_stock: true,
    },
    {
      name: '90C',
      id: '8',
      in_stock: true,
    },
    {
      name: '95C',
      id: '9',
      in_stock: true,
    },
    {
      name: '100C',
      id: '10',
      in_stock: true,
    },
    {
      name: '85D',
      id: '11',
      in_stock: true,
    },
    {
      name: '90D',
      id: '12',
      in_stock: true,
    },
    {
      name: '95D',
      id: '13',
      in_stock: true,
    },
    {
      name: '100D',
      id: '14',
      in_stock: true,
    },
    {
      name: '90E',
      id: '15',
      in_stock: true,
    },
    {
      name: '95E',
      id: '16',
      in_stock: true,
    },
  ],
};
const TANGA = {
  // For the demo
  id: 9837690,
  name: 'Female Underwear',
  price: 200,
  // For the plugin
  client_id: 5,
  client_product_id: 'femaleunderwear',
  image: `${process.env.REACT_APP_STATIC_URL}/plugin-demo-products/underwear.webp`,
  available_sizes: [
    {
      name: 'XS',
      id: '1',
      in_stock: false,
    },
    {
      name: 'S',
      id: '2',
      in_stock: true,
    },
    {
      name: 'M',
      id: '3',
      in_stock: true,
    },
    {
      name: 'L',
      id: '4',
      in_stock: true,
    },
    {
      name: 'XL',
      id: '5',
      in_stock: true,
    },
  ],
};

const UnderwearPage = () => {
  return (
    <div>
      <Product product={BRA} />
      <Product product={TANGA} />
    </div>
  );
};

export default UnderwearPage;
