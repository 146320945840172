import React from 'react';
import styled from 'styled-components/macro';
import Product from 'components/Product';
import products from 'data/products';

const ProductPageStyle = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
`;

const ProductContainer = styled.div`
  flex: 1;
`;

const ProductPage = props => {
  const productId = props.match.params.productId || 'pinkpolo';
  const product = products.find(p => p.id === productId);

  return (
    <ProductPageStyle>
      <ProductContainer>
        {/* The key is necessary to unmount ftl-target element and destroy the previous event listener for click */}
        <Product key={productId} product={product} />
      </ProductContainer>
    </ProductPageStyle>
  );
};

export default ProductPage;
