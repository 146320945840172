import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import ProductSelector from 'components/ProductSelector';

const Container = styled.div`
  height: 90px;
  border-bottom: 1px lightgray solid;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 850px) {
  .header {
    height: 50px;

    .header-right {
      font-size: 12px;
    }
  }
}
`;

const HeaderLeft = styled.div`
  height: 70%;
  max-height: 70%;
  display: flex;
  align-items: center;
  margin-left: 20px;

  & > img {
    max-height: 70%;
  }
`;

const HeaderRight = styled.div`
  cursor: pointer;
  font-weight: bold;
  margin-right: 30px;
  font-size: 1.5vw;

  @media (max-width: 850px) {
    font-size: 12px;
  }
`;

const Header = props => {
  const onChange = value => {
    props.history.replace('/' + value);
  };
  const currentValue = props.history.location.pathname.split('/')[1];

  return (
    <div>
      <Container>
        <HeaderLeft>
          <img
            alt="fitle logo"
            src={`${process.env.REACT_APP_STATIC_URL}/LogoFitleSignatureMail.png`}
          />
        </HeaderLeft>
        <HeaderRight>
          <div style={{ display: 'flex' }} onClick={props.toggleCart}>
            {props.showCart ? '>  Hide cart' : '<  Show cart'}
            <Icon name="shopping basket" style={{ marginLeft: 10 }} />
          </div>
        </HeaderRight>
      </Container>
      <ProductSelector value={currentValue} onChange={onChange} />
    </div>
  );
};

export default Header;
