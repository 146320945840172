import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import products from './data/products';
import 'semantic-ui-css/semantic.min.css';
import Header from 'components/Header';
import ProductPage from 'components/ProductPage';
import { Provider } from 'react-redux';
import store from 'store';
import Cart from 'components/Cart';
import UnderwearPage from 'components/UnderwearPage';

import { createBrowserHistory } from 'history';
import styled from 'styled-components/macro';
import { GlobalStyle } from 'styles';

const history = createBrowserHistory();

const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  position: relative;
`;

class App extends Component {
  state = { showCart: false };
  goToProduct = id => {
    history.push('/' + id);
  };
  toggleCart = () => {
    this.setState({ showCart: !this.state.showCart });
  };

  render() {
    return (
      <>
        <GlobalStyle />
        <Provider store={store}>
          <Router history={history}>
            <AppContainer>
              <Header
                products={products}
                history={history}
                showCart={this.state.showCart}
                toggleCart={this.toggleCart}
              />
              <Content>
                <Switch>
                  <Route path="/underwear" component={UnderwearPage} />
                  <Route path="/:productId?" component={ProductPage} />
                </Switch>
                <Cart
                  onHide={() => this.setState({ showCart: false })}
                  showCart={this.state.showCart}
                />
              </Content>
            </AppContainer>
          </Router>
        </Provider>
      </>
    );
  }
}

export default App;
