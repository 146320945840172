export default function reducer(state = { items: [] }, action) {
  switch (action.type) {
    case 'ADD_TO_CART': {
      const { product, size } = action;
      return { items: [...state.items, { product, size }] };
    }
    default:
      return state;
  }
}
