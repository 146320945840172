export default [
  {
    // For the demo
    id: 'pinkpolo',
    name: 'Pink Jersey Polo shirt',
    price: 130,
    // For the plugin
    client_id: 5,
    client_product_id: 'pinkpolo',
    image: `${process.env.REACT_APP_STATIC_URL}/plugin-demo-products/polo-short-sleeve-pink-in-pricked-jersey-cotton-brushed.jpg`,
    available_sizes: [
      {
        name: 'S',
        id: 'S',
        in_stock: true,
      },
      {
        name: 'M',
        id: 'M',
        in_stock: true,
      },
      {
        name: 'L',
        id: 'L',
        in_stock: false,
      },
      {
        name: 'XL',
        id: 'XL',
        in_stock: true,
      },
    ],
  },
  {
    // For the demo
    id: 'blackpolo',
    name: 'Black Jersey Polo Shirt',
    price: 130,
    // For the plugin
    client_id: 5,
    client_product_id: 'blackpolo',
    image: `${process.env.REACT_APP_STATIC_URL}/plugin-demo-products/polo-short-sleeve-black-in-pricked-jersey-cotton-brushed.jpg`,
    available_sizes: [
      {
        name: 'S',
        id: '1',
        in_stock: true,
      },
      {
        name: 'M',
        id: '2',
        in_stock: true,
      },
      {
        name: 'L',
        id: '3',
        in_stock: false,
      },
      {
        name: 'XL',
        id: '4',
        in_stock: true,
      },
      {
        name: 'XXL',
        id: '5',
        in_stock: true,
      },
    ],
  },
  {
    // For the demo
    id: 'fitledress',
    name: 'Fitle Dress',
    price: 9999,
    // For the plugin
    client_id: 5,
    client_product_id: 'fitledress',
    image: `${process.env.REACT_APP_STATIC_URL}/plugin-demo-products/fitle-dress.jpg`,
    available_sizes: [
      {
        name: '00',
        id: '1',
        in_stock: true,
      },
      {
        name: '0',
        id: '2',
        in_stock: true,
      },
      {
        name: '2',
        id: '3',
        in_stock: true,
      },
      {
        name: '4',
        id: '4',
        in_stock: true,
      },
      {
        name: '6',
        id: '5',
        in_stock: true,
      },
      {
        name: '8',
        id: '6',
        in_stock: true,
      },
      {
        name: '10',
        id: '7',
        in_stock: true,
      },
      {
        name: '12',
        id: '8',
        in_stock: false,
      },
      {
        name: '14',
        id: '9',
        in_stock: true,
      },
      {
        name: '16',
        id: '10',
        in_stock: false,
      },
      {
        name: '18W',
        id: '11',
        in_stock: true,
      },
      {
        name: '20W',
        id: '12',
        in_stock: true,
      },
      {
        name: '22W',
        id: '13',
        in_stock: true,
      },
      {
        name: '24W',
        id: '14',
        in_stock: true,
      },
      {
        name: '26W',
        id: '15',
        in_stock: true,
      },
      {
        name: '28W',
        id: '16',
        in_stock: false,
      },
      {
        name: '30W',
        id: '18',
        in_stock: true,
      },
    ],
  },
  {
    // For the demo
    id: 'pantsblack',
    name: 'Black pants',
    price: 200,
    // For the plugin
    client_id: 5,
    client_product_id: 'pantsblack',
    image: `${process.env.REACT_APP_STATIC_URL}/plugin-demo-products/black-pants.jpeg`,
    available_sizes: [
      {
        name: '26',
        id: '1',
        in_stock: true,
      },
      {
        name: '28',
        id: '2',
        in_stock: false,
      },
      {
        name: '30',
        id: '3',
        in_stock: true,
      },
      {
        name: '32',
        id: '4',
        in_stock: true,
      },
      {
        name: '34',
        id: '5',
        in_stock: true,
      },
      {
        name: '36',
        id: '6',
        in_stock: false,
      },
      {
        name: '38',
        id: '7',
        in_stock: true,
      },
    ],
  },
  {
    // For the demo
    id: 'pantspink',
    name: 'Pink pants',
    price: 200,
    // For the plugin
    client_id: 5,
    client_product_id: 'pantspink',
    image: `${process.env.REACT_APP_STATIC_URL}/plugin-demo-products/pink-pants.jpeg`,
    available_sizes: [
      {
        name: '1',
        id: '1',
        in_stock: false,
      },
      {
        name: '3',
        id: '2',
        in_stock: true,
      },
      {
        name: '5',
        id: '3',
        in_stock: true,
      },
      {
        name: '7',
        id: '4',
        in_stock: true,
      },
      {
        name: '9',
        id: '5',
        in_stock: true,
      },
      {
        name: '11',
        id: '6',
        in_stock: true,
      },
    ],
  },
  {
    // For the demo
    id: 'jumpsuit',
    name: 'Jumpsuit Black',
    price: 200,
    // For the plugin
    client_id: 5,
    client_product_id: 'jumpsuit',
    image: `${process.env.REACT_APP_STATIC_URL}/plugin-demo-products/crystal-jumpsuit-black-3.jpg`,
    available_sizes: [
      {
        name: 'XS',
        id: '1',
        in_stock: false,
      },
      {
        name: 'S',
        id: '2',
        in_stock: true,
      },
      {
        name: 'M',
        id: '3',
        in_stock: true,
      },
      {
        name: 'L',
        id: '4',
        in_stock: true,
      },
    ],
  },
  // {
  //   // For the demo
  //   id: 897825,
  //   name: 'Shining Shoe (with width)',
  //   price: 150,
  //   // For the plugin
  //   client_id: 5,
  //   client_product_id: 'testshoe',
  //   image: `${process.env.REACT_APP_STATIC_URL}/plugin-demo-products/shining-shoes.jpg`,
  //   available_sizes: Array.from({ length: 12 }, (_, i) => ({
  //     name: String(35 + i / 2),
  //     id: i,
  //     in_stock: i % 3 !== 0,
  //   })),
  // },
  {
    // For the demo
    id: 'nowidthshoe',
    name: 'Sneaker',
    price: 140,
    // For the plugin
    client_id: 5,
    client_product_id: 'nowidthshoe',
    image: `${process.env.REACT_APP_STATIC_URL}/plugin-demo-products/no-width-sneaker.jpg`,
    available_sizes: Array.from({ length: 12 }, (_, i) => ({
      name: String(35 + i / 2),
      id: i,
      in_stock: i % 3 !== 0,
    })),
  },
  {
    // For the demo
    id: 'chino-supercut',
    name: 'Chino Supercut',
    price: 80,
    // For the plugin
    client_id: 5,
    client_product_id: 'chino-supercut',
    image: `${process.env.REACT_APP_STATIC_URL}/plugin-demo-products/chino-supercut.jpg`,
    available_sizes: [
      { in_stock: true, id: 2756, name: '29 - SLIM - S', order: 0 },
      { in_stock: true, id: 2757, name: '30 - SLIM - S', order: 1 },
      { in_stock: true, id: 2758, name: '31 - SLIM - S', order: 2 },
      { in_stock: true, id: 2759, name: '32 - SLIM - S', order: 3 },
      { in_stock: true, id: 2760, name: '33 - SLIM - S', order: 4 },
      { in_stock: true, id: 2761, name: '34 - SLIM - S', order: 5 },
      { in_stock: true, id: 2762, name: '35 - SLIM - S', order: 6 },
      { in_stock: true, id: 2763, name: '36 - SLIM - S', order: 7 },
      { in_stock: true, id: 2764, name: '37 - SLIM - S', order: 8 },
      { in_stock: true, id: 2765, name: '38 - SLIM - S', order: 9 },
      { in_stock: true, id: 2766, name: '40 - SLIM - S', order: 10 },
      { in_stock: true, id: 2767, name: '42 - SLIM - S', order: 11 },
      { in_stock: true, id: 2768, name: '44 - SLIM - S', order: 12 },
      { in_stock: true, id: 2769, name: '29 - SLIM - M', order: 13 },
      { in_stock: true, id: 2770, name: '30 - SLIM - M', order: 14 },
      { in_stock: true, id: 2771, name: '31 - SLIM - M', order: 15 },
      { in_stock: true, id: 2772, name: '32 - SLIM - M', order: 16 },
      { in_stock: true, id: 2773, name: '33 - SLIM - M', order: 17 },
      { in_stock: true, id: 2774, name: '34 - SLIM - M', order: 18 },
      { in_stock: true, id: 2775, name: '35 - SLIM - M', order: 19 },
      { in_stock: true, id: 2776, name: '36 - SLIM - M', order: 20 },
      { in_stock: true, id: 2777, name: '37 - SLIM - M', order: 21 },
      { in_stock: true, id: 2778, name: '38 - SLIM - M', order: 22 },
      { in_stock: true, id: 2779, name: '40 - SLIM - M', order: 23 },
      { in_stock: true, id: 2780, name: '42 - SLIM - M', order: 24 },
      { in_stock: true, id: 2781, name: '44 - SLIM - M', order: 25 },
      { in_stock: true, id: 2782, name: '29 - SLIM - L', order: 26 },
      { in_stock: true, id: 2783, name: '30 - SLIM - L', order: 27 },
      { in_stock: true, id: 2784, name: '31 - SLIM - L', order: 28 },
      { in_stock: true, id: 2785, name: '32 - SLIM - L', order: 29 },
      { in_stock: true, id: 2786, name: '33 - SLIM - L', order: 30 },
      { in_stock: true, id: 2787, name: '34 - SLIM - L', order: 31 },
      { in_stock: true, id: 2788, name: '35 - SLIM - L', order: 32 },
      { in_stock: true, id: 2789, name: '36 - SLIM - L', order: 33 },
      { in_stock: true, id: 2790, name: '37 - SLIM - L', order: 34 },
      { in_stock: true, id: 2791, name: '38 - SLIM - L', order: 35 },
      { in_stock: true, id: 2792, name: '40 - SLIM - L', order: 36 },
      { in_stock: true, id: 2793, name: '42 - SLIM - L', order: 37 },
      { in_stock: true, id: 2794, name: '44 - SLIM - L', order: 38 },
      { in_stock: true, id: 2795, name: '29 - SLIM - XL', order: 39 },
      { in_stock: true, id: 2796, name: '30 - SLIM - XL', order: 40 },
      { in_stock: true, id: 2797, name: '31 - SLIM - XL', order: 41 },
      { in_stock: true, id: 2798, name: '32 - SLIM - XL', order: 42 },
      { in_stock: true, id: 2799, name: '33 - SLIM - XL', order: 43 },
      { in_stock: true, id: 2800, name: '34 - SLIM - XL', order: 44 },
      { in_stock: true, id: 2801, name: '35 - SLIM - XL', order: 45 },
      { in_stock: true, id: 2802, name: '36 - SLIM - XL', order: 46 },
      { in_stock: true, id: 2803, name: '37 - SLIM - XL', order: 47 },
      { in_stock: true, id: 2804, name: '38 - SLIM - XL', order: 48 },
      { in_stock: true, id: 2805, name: '40 - SLIM - XL', order: 49 },
      { in_stock: true, id: 2806, name: '42 - SLIM - XL', order: 50 },
      { in_stock: true, id: 2807, name: '44 - SLIM - XL', order: 51 },
      { in_stock: true, id: 2808, name: '29 - SLIM - XXL', order: 52 },
      { in_stock: true, id: 2809, name: '30 - SLIM - XXL', order: 53 },
      { in_stock: true, id: 2810, name: '31 - SLIM - XXL', order: 54 },
      { in_stock: true, id: 2811, name: '32 - SLIM - XXL', order: 55 },
      { in_stock: true, id: 2812, name: '33 - SLIM - XXL', order: 56 },
      { in_stock: true, id: 2813, name: '34 - SLIM - XXL', order: 57 },
      { in_stock: true, id: 2814, name: '35 - SLIM - XXL', order: 58 },
      { in_stock: true, id: 2815, name: '36 - SLIM - XXL', order: 59 },
      { in_stock: true, id: 2816, name: '37 - SLIM - XXL', order: 60 },
      { in_stock: true, id: 2817, name: '38 - SLIM - XXL', order: 61 },
      { in_stock: true, id: 2818, name: '40 - SLIM - XXL', order: 62 },
      { in_stock: true, id: 2819, name: '42 - SLIM - XXL', order: 63 },
      { in_stock: true, id: 2820, name: '44 - SLIM - XXL', order: 64 },
      { in_stock: true, id: 2821, name: '29 - REGULAR - S', order: 65 },
      { in_stock: true, id: 2822, name: '30 - REGULAR - S', order: 66 },
      { in_stock: true, id: 2823, name: '31 - REGULAR - S', order: 67 },
      { in_stock: true, id: 2824, name: '32 - REGULAR - S', order: 68 },
      { in_stock: true, id: 2825, name: '33 - REGULAR - S', order: 69 },
      { in_stock: true, id: 2826, name: '34 - REGULAR - S', order: 70 },
      { in_stock: true, id: 2827, name: '35 - REGULAR - S', order: 71 },
      { in_stock: true, id: 2828, name: '36 - REGULAR - S', order: 72 },
      { in_stock: true, id: 2829, name: '37 - REGULAR - S', order: 73 },
      { in_stock: true, id: 2830, name: '38 - REGULAR - S', order: 74 },
      { in_stock: true, id: 2831, name: '40 - REGULAR - S', order: 75 },
      { in_stock: true, id: 2832, name: '42 - REGULAR - S', order: 76 },
      { in_stock: true, id: 2833, name: '44 - REGULAR - S', order: 77 },
      { in_stock: true, id: 2834, name: '29 - REGULAR - M', order: 78 },
      { in_stock: true, id: 2835, name: '30 - REGULAR - M', order: 79 },
      { in_stock: true, id: 2836, name: '31 - REGULAR - M', order: 80 },
      { in_stock: true, id: 2837, name: '32 - REGULAR - M', order: 81 },
      { in_stock: true, id: 2838, name: '33 - REGULAR - M', order: 82 },
      { in_stock: true, id: 2839, name: '34 - REGULAR - M', order: 83 },
      { in_stock: true, id: 2840, name: '35 - REGULAR - M', order: 84 },
      { in_stock: false, id: 2841, name: '36 - REGULAR - M', order: 85 },
      { in_stock: true, id: 2842, name: '37 - REGULAR - M', order: 86 },
      { in_stock: true, id: 2843, name: '38 - REGULAR - M', order: 87 },
      { in_stock: true, id: 2844, name: '40 - REGULAR - M', order: 88 },
      { in_stock: true, id: 2845, name: '42 - REGULAR - M', order: 89 },
      { in_stock: true, id: 2846, name: '44 - REGULAR - M', order: 90 },
      { in_stock: true, id: 2847, name: '29 - REGULAR - L', order: 91 },
      { in_stock: true, id: 2848, name: '30 - REGULAR - L', order: 92 },
      { in_stock: true, id: 2849, name: '31 - REGULAR - L', order: 93 },
      { in_stock: true, id: 2850, name: '32 - REGULAR - L', order: 94 },
      { in_stock: true, id: 2851, name: '33 - REGULAR - L', order: 95 },
      { in_stock: true, id: 2852, name: '34 - REGULAR - L', order: 96 },
      { in_stock: true, id: 2853, name: '35 - REGULAR - L', order: 97 },
      { in_stock: true, id: 2854, name: '36 - REGULAR - L', order: 98 },
      { in_stock: true, id: 2855, name: '37 - REGULAR - L', order: 99 },
      { in_stock: true, id: 2856, name: '38 - REGULAR - L', order: 100 },
      { in_stock: false, id: 2857, name: '40 - REGULAR - L', order: 101 },
      { in_stock: true, id: 2858, name: '42 - REGULAR - L', order: 102 },
      { in_stock: true, id: 2859, name: '44 - REGULAR - L', order: 103 },
      { in_stock: true, id: 2860, name: '29 - REGULAR - XL', order: 104 },
      { in_stock: true, id: 2861, name: '30 - REGULAR - XL', order: 105 },
      { in_stock: true, id: 2862, name: '31 - REGULAR - XL', order: 106 },
      { in_stock: true, id: 2863, name: '32 - REGULAR - XL', order: 107 },
      { in_stock: true, id: 2864, name: '33 - REGULAR - XL', order: 108 },
      { in_stock: true, id: 2865, name: '34 - REGULAR - XL', order: 109 },
      { in_stock: true, id: 2866, name: '35 - REGULAR - XL', order: 110 },
      { in_stock: true, id: 2867, name: '36 - REGULAR - XL', order: 111 },
      { in_stock: true, id: 2868, name: '37 - REGULAR - XL', order: 112 },
      { in_stock: true, id: 2869, name: '38 - REGULAR - XL', order: 113 },
      { in_stock: true, id: 2870, name: '40 - REGULAR - XL', order: 114 },
      { in_stock: true, id: 2871, name: '42 - REGULAR - XL', order: 115 },
      { in_stock: true, id: 2872, name: '44 - REGULAR - XL', order: 116 },
      { in_stock: true, id: 2873, name: '29 - REGULAR - XXL', order: 117 },
      { in_stock: true, id: 2874, name: '30 - REGULAR - XXL', order: 118 },
      { in_stock: true, id: 2875, name: '31 - REGULAR - XXL', order: 119 },
      { in_stock: true, id: 2876, name: '32 - REGULAR - XXL', order: 120 },
      { in_stock: true, id: 2877, name: '33 - REGULAR - XXL', order: 121 },
      { in_stock: true, id: 2878, name: '34 - REGULAR - XXL', order: 122 },
      { in_stock: true, id: 2879, name: '35 - REGULAR - XXL', order: 123 },
      { in_stock: true, id: 2880, name: '36 - REGULAR - XXL', order: 124 },
      { in_stock: true, id: 2881, name: '37 - REGULAR - XXL', order: 125 },
      { in_stock: true, id: 2882, name: '38 - REGULAR - XXL', order: 126 },
      { in_stock: true, id: 2883, name: '40 - REGULAR - XXL', order: 127 },
      { in_stock: true, id: 2884, name: '42 - REGULAR - XXL', order: 128 },
      { in_stock: true, id: 2885, name: '44 - REGULAR - XXL', order: 129 },
      { in_stock: true, id: 2886, name: '29 - ATHLETIC - S', order: 130 },
      { in_stock: true, id: 2887, name: '30 - ATHLETIC - S', order: 131 },
      { in_stock: true, id: 2888, name: '31 - ATHLETIC - S', order: 132 },
      { in_stock: true, id: 2889, name: '32 - ATHLETIC - S', order: 133 },
      { in_stock: true, id: 2890, name: '33 - ATHLETIC - S', order: 134 },
      { in_stock: true, id: 2891, name: '34 - ATHLETIC - S', order: 135 },
      { in_stock: true, id: 2892, name: '35 - ATHLETIC - S', order: 136 },
      { in_stock: true, id: 2893, name: '36 - ATHLETIC - S', order: 137 },
      { in_stock: true, id: 2894, name: '37 - ATHLETIC - S', order: 138 },
      { in_stock: true, id: 2895, name: '38 - ATHLETIC - S', order: 139 },
      { in_stock: true, id: 2896, name: '40 - ATHLETIC - S', order: 140 },
      { in_stock: true, id: 2897, name: '42 - ATHLETIC - S', order: 141 },
      { in_stock: true, id: 2898, name: '44 - ATHLETIC - S', order: 142 },
      { in_stock: true, id: 2899, name: '29 - ATHLETIC - M', order: 143 },
      { in_stock: true, id: 2900, name: '30 - ATHLETIC - M', order: 144 },
      { in_stock: true, id: 2901, name: '31 - ATHLETIC - M', order: 145 },
      { in_stock: true, id: 2902, name: '32 - ATHLETIC - M', order: 146 },
      { in_stock: true, id: 2903, name: '33 - ATHLETIC - M', order: 147 },
      { in_stock: true, id: 2904, name: '34 - ATHLETIC - M', order: 148 },
      { in_stock: false, id: 2905, name: '35 - ATHLETIC - M', order: 149 },
      { in_stock: true, id: 2906, name: '36 - ATHLETIC - M', order: 150 },
      { in_stock: true, id: 2907, name: '37 - ATHLETIC - M', order: 151 },
      { in_stock: true, id: 2908, name: '38 - ATHLETIC - M', order: 152 },
      { in_stock: true, id: 2909, name: '40 - ATHLETIC - M', order: 153 },
      { in_stock: true, id: 2910, name: '42 - ATHLETIC - M', order: 154 },
      { in_stock: true, id: 2911, name: '44 - ATHLETIC - M', order: 155 },
      { in_stock: true, id: 2912, name: '29 - ATHLETIC - L', order: 156 },
      { in_stock: true, id: 2913, name: '30 - ATHLETIC - L', order: 157 },
      { in_stock: true, id: 2914, name: '31 - ATHLETIC - L', order: 158 },
      { in_stock: true, id: 2915, name: '32 - ATHLETIC - L', order: 159 },
      { in_stock: true, id: 2916, name: '33 - ATHLETIC - L', order: 160 },
      { in_stock: true, id: 2917, name: '34 - ATHLETIC - L', order: 161 },
      { in_stock: true, id: 2918, name: '35 - ATHLETIC - L', order: 162 },
      { in_stock: true, id: 2919, name: '36 - ATHLETIC - L', order: 163 },
      { in_stock: true, id: 2920, name: '37 - ATHLETIC - L', order: 164 },
      { in_stock: true, id: 2921, name: '38 - ATHLETIC - L', order: 165 },
      { in_stock: true, id: 2922, name: '40 - ATHLETIC - L', order: 166 },
      { in_stock: true, id: 2923, name: '42 - ATHLETIC - L', order: 167 },
      { in_stock: true, id: 2924, name: '44 - ATHLETIC - L', order: 168 },
      { in_stock: true, id: 2925, name: '29 - ATHLETIC - XL', order: 169 },
      { in_stock: true, id: 2926, name: '30 - ATHLETIC - XL', order: 170 },
      { in_stock: true, id: 2927, name: '31 - ATHLETIC - XL', order: 171 },
      { in_stock: true, id: 2928, name: '32 - ATHLETIC - XL', order: 172 },
      { in_stock: true, id: 2929, name: '33 - ATHLETIC - XL', order: 173 },
      { in_stock: true, id: 2930, name: '34 - ATHLETIC - XL', order: 174 },
      { in_stock: true, id: 2931, name: '35 - ATHLETIC - XL', order: 175 },
      { in_stock: true, id: 2932, name: '36 - ATHLETIC - XL', order: 176 },
      { in_stock: true, id: 2933, name: '37 - ATHLETIC - XL', order: 177 },
      { in_stock: true, id: 2934, name: '38 - ATHLETIC - XL', order: 178 },
      { in_stock: true, id: 2935, name: '40 - ATHLETIC - XL', order: 179 },
      { in_stock: true, id: 2936, name: '42 - ATHLETIC - XL', order: 180 },
      { in_stock: true, id: 2937, name: '44 - ATHLETIC - XL', order: 181 },
      { in_stock: true, id: 2938, name: '29 - ATHLETIC - XXL', order: 182 },
      { in_stock: true, id: 2939, name: '30 - ATHLETIC - XXL', order: 183 },
      { in_stock: true, id: 2940, name: '31 - ATHLETIC - XXL', order: 184 },
      { in_stock: true, id: 2941, name: '32 - ATHLETIC - XXL', order: 185 },
      { in_stock: true, id: 2942, name: '33 - ATHLETIC - XXL', order: 186 },
      { in_stock: true, id: 2943, name: '34 - ATHLETIC - XXL', order: 187 },
      { in_stock: false, id: 2944, name: '35 - ATHLETIC - XXL', order: 188 },
      { in_stock: true, id: 2945, name: '36 - ATHLETIC - XXL', order: 189 },
      { in_stock: true, id: 2946, name: '37 - ATHLETIC - XXL', order: 190 },
      { in_stock: true, id: 2947, name: '38 - ATHLETIC - XXL', order: 191 },
      { in_stock: true, id: 2948, name: '40 - ATHLETIC - XXL', order: 192 },
      { in_stock: true, id: 2949, name: '43 - ATHLETIC - XXL', order: 193 },
      { in_stock: true, id: 2950, name: '44 - ATHLETIC - XXL', order: 194 },
    ],
  },
  {
    // For the demo
    id: 'cow_costume',
    name: 'Cow costume',
    price: 90,
    // For the plugin
    client_id: 5,
    client_product_id: 'cow_costume',
    image: `${process.env.REACT_APP_STATIC_URL}/plugin-demo-products/cow_costume.jpg`,
    available_sizes: [{ name: 'TU', id: 'TU', in_stock: true }],
  },
  {
    id: 'underwear',
    name: 'Underwear',
  },
  {
    // For the demo
    id: 'a_ring',
    name: 'Ring (not associated)',
    price: 10000,
    // For the plugin
    client_id: 5,
    client_product_id: 'a_ring',
    image: `${process.env.REACT_APP_STATIC_URL}/plugin-demo-products/ring.jpeg`,
    available_sizes: Array.from({ length: 8 }, (_, i) => ({
      name: String(48 + i),
      id: i,
      in_stock: i % 3 !== 0,
    })),
  },
  {
    // For the demo
    id: 'child_coat',
    name: 'Child coat',
    price: 30,
    // For the plugin
    client_id: 5,
    client_product_id: 'child_coat',
    image: `${process.env.REACT_APP_STATIC_URL}/plugin-demo-products/child_coat.jpg`,
    available_sizes: [
      { name: '8 ans', id: 8, in_stock: true },
      { name: '10 ans', id: 10, in_stock: true },
      { name: '12 ans', id: 12, in_stock: true },
      { name: '14 ans', id: 14, in_stock: true },
      { name: '16 ans', id: 16, in_stock: true },
    ],
  },
  {
    // For the demo
    id: 'testyann',
    name: 'Test Yann',
    price: 99,
    // For the plugin
    client_id: 5,
    client_product_id: 'testyann',
    image: '',
    available_sizes: [],
  },
];
