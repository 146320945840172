import React from 'react';
import styled, { css } from 'styled-components/macro';
import cn from 'classnames';

const SizeSelectorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 1em 0;
  @media (max-width: 650px) {
    justify-content: center;
  }
`;

const Size = styled.div`
  border: 1.5px black solid;
  min-width: 2.5em;
  height: 2.5em;
  margin-right: 5px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25em;
  font-weight: bold;
  cursor: pointer;
  margin-top: 5px;
  padding: 5px;
  &:hover {
    background: lightgrey;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: rgb(202, 235, 247);
      cursor: default;
      &:hover {
        background: rgb(202, 235, 247);
      }
    `} &-selected {
  }

  ${({ available }) =>
    !available &&
    css`
      border-color: white;
      background-color: lightgrey;
      color: grey;
      cursor: default;
    `};

  @media (max-width: 650px) {
    font-size: 1em;
  }
`;

const SizeSelector = ({ sizes, value, onChange }) => {
  const content = sizes.map(size => {
    const { id, name, in_stock } = size;
    const selected = value && value.id === id;
    const onClick = () => !selected && in_stock && onChange(size);
    return (
      <Size
        className={cn({ size: true, 'size-selected': selected, 'size-unavailable': !in_stock })}
        id={id}
        available={in_stock}
        selected={selected}
        onClick={onClick}
        key={id}
      >
        {name}
      </Size>
    );
  });
  return <SizeSelectorContainer>{content}</SizeSelectorContainer>;
};

export default SizeSelector;
