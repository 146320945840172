import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import React from 'react';
import products from 'data/products';

const options = products.map(p => ({
  value: '' + p.id,
  text: p.name,
  image: { avatar: true, src: p.image },
}));

const ProductSelectorContainer = styled.div`
  margin: 15px 10px;

  && .menu.transition.visible {
    max-height: 50vh;
  }
`;

const ProductSelector = ({ value, onChange }) => {
  return (
    <ProductSelectorContainer>
      <Dropdown
        fluid
        selection
        options={options}
        onChange={(e, { value }) => onChange(value)}
        value={value}
      />
    </ProductSelectorContainer>
  );
};

export default ProductSelector;
