import { createStore, combineReducers } from 'redux';
import basket from './basket';

const appReducer = combineReducers({
  basket,
});

const store = createStore(
  appReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
