import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import SizeSelector from './SizeSelector';

const Container = styled.div`
  display: flex;
  .product-image-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    height: 85vh;

    .product-image {
      max-height: 50vh;
      max-width: 100%;
      object-fit: contain;
    }

    @media (max-width: 650px) {
      max-height: 50vh;
      padding: 0;
    }
  }

  .product-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 50px;
    justify-content: space-around;

    .product-description {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-around;
      margin: 1em 0;

      .product-name {
        font-weight: bold;
        font-size: 2em;
        line-height: 1em;
      }

      .price {
        margin-top: 20px;
        font-size: 1.5em;
      }
    }

    .product-size-section {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 650px) {
        flex-direction: column;
      }
    }

    .cart-button {
      background: black;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      margin: 1em 0;
      cursor: pointer;

      &:hover {
        background: grey;
      }

      @media (max-width: 650px) {
        padding: 10px;
      }
    }

    @media (max-width: 650px) {
      padding: 10px;
    }
  }

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const FitleTargetButton = styled.button.attrs(props => ({
  type: 'button',
}))`
  display: 'none';
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
`;

const Price = ({ price, currency }) => {
  return (
    <div className="price">
      {price} {currency === 'EUR' ? '€' : '$'}
    </div>
  );
};

const addProductIntegrationScript = product => {
  const $script = document.createElement('script');
  $script.id = `ftl-integration-${product.id}`;
  $script.type = 'text/javascript';
  const text = `
  target = document.getElementById('ftl-target-${product.id}');
  // Cannot use arrow functions in IE 11!!!
  availableSizes = Array.from(document.getElementsByClassName('size'))
    .filter(function(el) {
      return !el.classList.contains('size-unavailable');
    })
    .map(function(el) {
      return { name: el.innerText, id: el.id };
    });
  var args = {
    client_id:${product.client_id},
    product_id:'${product.client_product_id}',
    product_image:'${product.image}',
    end_behavior: 'select-size',
    language: 'en',
    unit_system: 'metric',
    target: target, // IE 11 does not support implicit assignment
    available_sizes: availableSizes,
    shop_country: 'FR',
    tooltip_position: 'upper-right'
  };
  window.Fitle.createWidget(args).then(function(widget) {
    if (widget) {
      target.style.display = 'block';
      widget.on('recommendation', function(size) {
        document.getElementById(size.id).click();
      });
    }
  });
  window.Fitle.sendDebugEvent({client_id: 5, info: "This is a test"});
  `;
  $script.text = text;
  document.body.appendChild($script);
  return () => {
    document.body.removeChild($script);
  };
};

const ProductPage = ({ product, addToCart }) => {
  const [size, setSize] = useState(null);
  useEffect(() => addProductIntegrationScript(product), [product]);

  const handleCartClick = () => {
    if (!size) return;
    window.Fitle.sendEvent({
      event: 'cart',
      client_id: 5,
      product_id: product.client_product_id,
      size: size.name,
    });
    addToCart(product, size);
  };
  return (
    <Container>
      <div className="product-image-container">
        <img src={product.image} className="product-image" alt="product" />
      </div>
      <div className="product-details">
        <div className="product-description">
          <div className="product-name">{product.name}</div>
          <Price price={product.price} currency={product.currency} />
        </div>
        <div className="product-size-section">
          <SizeSelector value={size} sizes={product.available_sizes} onChange={setSize} />
          <FitleTargetButton id={`ftl-target-${product.id}`} />
        </div>
        <button disabled={!size} className="cart-button" onClick={handleCartClick}>
          ADD TO CART
        </button>
      </div>
    </Container>
  );
};

const dispatcher = dispatch => ({
  addToCart: (product, size) => dispatch({ type: 'ADD_TO_CART', product, size }),
});

export default connect(state => ({}), dispatcher)(ProductPage);
